<template>
  <a-spin :spinning="loading" tip="加载中">
    <div style="padding: 10px">
      <p-button
        type="primary"
        @click="show"
        style="margin-bottom: 10px"
        name="设置菜谱"
        :roles="['每周菜谱-发布']"
      />
      <div>{{ `${month}月 第${num}周` }}</div>
      <div v-html="content" class="cookbook"></div>
      <a-modal
        width="80%"
        :visible="visible"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
        title="设置菜谱"
        @cancel="handleCancel"
      >
        <cookbooklist />
      </a-modal>
    </div>
  </a-spin>
</template>
<script>
import cookbooklist from './cookbooklist.vue';
import api from '@/api/personal/cookbook';
import request from '@/request';
export default {
  name: 'cookbook',
  data() {
    return {
      content: '',
      month: '',
      num: 0,
      visible: false,
      loading: false,
      myConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 600,
        wordCount: true,
        maximumWords: 100000,
        initialFrameWidth: '100%',
        serverUrl: '',
        UEDITOR_HOME_URL: '/UEditor/',
        toolbars: this.$config.editorConfig.toolbars
      }
    };
  },
  components: {
    cookbooklist
  },
  mounted() {
    this.get();
    this.myConfig.serverUrl = request.getUploadUrl('oa');
  },
  methods: {
    show() {
      this.visible = true;
    },

    handleCancel() {
      this.visible = false;
    },
    get() {
      this.loading = true;
      api.getLast().then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.content = res.data.content;
          this.month = res.data.month;
          this.num = res.data.num;
        } else {
          this.content = '暂无记录';
        }
      });
    }
  }
};
</script>
<style>
.cookbook table,
.cookbook table tr,
.cookbook table td {
  border: solid 1px #cccccc;
}
.cookbook table td {
  padding: 5px;
}
</style>