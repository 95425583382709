<template>
  <a-spin :spinning="loading" tip="加载中">
    <div>
      <a-form-model
        ref="articleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="月份" prop="month">
          <a-month-picker v-model="form.month" />
        </a-form-model-item>
        <a-form-model-item label="周" prop="num">
          <a-select v-model="form.num" style="width: 100px">
            <a-select-option value="1">1</a-select-option>
            <a-select-option value="2">2</a-select-option>
            <a-select-option value="3">3</a-select-option>
            <a-select-option value="4">4</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="内容" prop="content">
          <vue-ueditor-wrap
            editor-id="content"
            :config="myConfig"
            v-model="form.content"
          ></vue-ueditor-wrap>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 19, offset: 4 }">
          <a-button
            type="primary"
            v-if="form.processExecuteStatus && form.taskKey == '其他-审批'"
            style="margin-right: 5px"
            @click="showaudit()"
            >审批</a-button
          >
          <a-button
            type="primary"
            v-if="form.status == 0 || form.status == 3"
            @click="handleSubmit(0)"
            >暂存</a-button
          >
          <a-button
            type="danger"
            v-if="form.status == 0 || form.status == 3"
            style="margin-left: 10px"
            @click="handleSubmit(1)"
            >提交</a-button
          >
          <a-button @click="cancelClick" style="margin-left: 10px"
            >取消</a-button
          >
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal
      title="审批"
      v-model="auditModal.visible"
      width="40%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
  </a-spin>
</template>
<script>
import VueUeditorWrap from 'vue-ueditor-wrap';
import api from '@/api/personal/cookbook';
import request from '@/request';
import cookbookApi from '@/api/personal/cookbook';
export default {
  name: 'cookbook',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        month: null,
        status: 0
      },
      rules: {
        month: [
          {
            required: true,
            message: '请选择月份',
            trigger: 'blur'
          }
        ],
        num: [
          {
            required: true,
            message: '请选择周',
            trigger: 'blur'
          }
        ]
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      visible: false,
      loading: false,
      myConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 600,
        wordCount: true,
        maximumWords: 100000,
        initialFrameWidth: '100%',
        serverUrl: '',
        UEDITOR_HOME_URL: '/UEditor/',
        toolbars: this.$config.editorConfig.toolbars
      },
      auditModal: {
        info: null,
        visible: false
      }
    };
  },
  components: {
    VueUeditorWrap
  },
  mounted() {
    this.myConfig.serverUrl = request.getUploadUrl('oa');
    if (this.info && this.info.id != 0) {
      this.getInfo();
    } else {
      var d = new Date();
      this.form.month = this.$moment(d);
    }
  },
  methods: {
    auditCallBack(data) {
      this.auditModal.visible = false;
      this.$emit('callback', data != null);
    },
    showaudit() {
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    getInfo() {
      this.loading = true;
      api.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.form = res.data;
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    handleSubmit(s) {
      this.$refs.articleForm.validate((valid) => {
        if (valid) {
          this.isloading = true;
          this.loadingTip = '保存中...';
          let formData = {
            ...this.form
          };
          formData.month = formData.month.format('YYYY-MM');
          formData.status = s;
          if (this.infos && this.infos.id != 0) {
            formData.id = this.infos.id;
          }
          cookbookApi
            .addOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == this.$commons.RespCode.success) {
                this.$message.success(res.errorMsg);
                this.$emit('callback', true);
              } else {
                this.$message.error(res.errorMsg);
              }
              this.isloading = false;
            })
            .catch(() => {
              this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },

    /**取消 */
    cancelClick() {
      this.$emit('callback');
    }
  }
};
</script>
<style>
.ant-calendar-footer-extra span {
  margin: 0 9px;
  cursor: pointer;
}
#content div {
  line-height: 1.5;
}
</style>