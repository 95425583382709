import request from '@/request';
const part_url = "/oa/cookbook/";

export default{
    addOrUpdate(params) {
        let url = part_url + "addOrUpdate";
        return request.post(url,{},params);
    },
    getList(params) {
        let url = part_url + "getList";
        return request.get(url,params, {});
    },
    changeStatus(id,status){
        let url = part_url + "changeStatus";
        return request.get(url,{id,status}, {});
    },
    getData(id) {
        let url = part_url + "getData";
        return request.get(url, {id}, {});
    },
  
    getLast(){
        let url = part_url + "getLast";
        return request.get(url, {}, {});
    }
}